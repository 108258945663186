body {
  position: relative;
  margin: 0;
  font-family: Lucida Sans, Lucida Sans Unicode, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;

  margin: 0;
  padding: 0;
}

*:focus {
  outline: none;
}
